
// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

html, body {
  height: 100%;
}

.plan-box {
  text-align: center;
}

.main-header .navbar {
  height: 50px;
}

.sidebar-button {
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

.list-table-header {
  padding-top: 20px;
}

hr {
  border-top: 1px solid #4c4c4c;
}

a.sidebar-toggle {
  height: 50px;
}

a.sidebar-toggle:hover {
  text-decoration: none;
}

a.logo:hover {
  text-decoration: none;
}

input {
  max-width: 100%;
}

.info-box-number {
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Carousel overrides */
.carousel-inner .item img {
  margin-left: auto;
  margin-right: auto;
}

.fill {
  height: 100% !important;
  min-height: 100% !important;
}

.chart {
  //min-height: 400px;
}

.chart-legend li span{
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.more-important-well {
  background-color: #e0e0e0;
}

.sidebar-menu li {
  border-bottom: 1px solid #e0e0e0;
}

.sidebar-menu li a.active {
  color: rgba(84,160,210,1.0) !important;
}

.add-button-centre {
  margin-top: -5px;
  margin-bottom: 10px;
}

.welcome-table {
  width: 100%;
}

.welcome-table td {
  width: 50%;
}

.welcome-table td img {
  display: block;
  margin-left: auto;
  margin-right: 0;

}

.white, .white a {
  color: #fff;
}

.plan hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #f4f4f4;
  margin: 1em 0;
  padding: 0;
}

/* CSS-only ribbon styles    */
.cnrflash
{
  /*Position correctly within container*/
  position: absolute;
  top: -11px;
  right: -12px;
  z-index: 1; /*Set overflow to hidden, to mask inner square*/
  overflow: hidden; /*Set size and add subtle rounding  		to soften edges*/
  width: 100px;
  height: 100px;
  border-radius: 3px 5px 3px 0;
}
.cnrflash-inner
{
  /*Set position, make larger then 			container and rotate 45 degrees*/
  position: absolute;
  bottom: 0;
  right: 0;
  width: 145px;
  height: 145px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -o-transform: rotate(45deg); /* Opera */
  -moz-transform: rotate(45deg); /* Firefox */
  -webkit-transform: rotate(45deg); /* Safari and Chrome */
  -webkit-transform-origin: 100% 100%; /*Purely decorative effects to add texture and stuff*/ /* Safari and Chrome */
  -ms-transform-origin: 100% 100%;  /* IE 9 */
  -o-transform-origin: 100% 100%; /* Opera */
  -moz-transform-origin: 100% 100%; /* Firefox */
  background-image: linear-gradient(90deg, transparent 50%, rgba(255,255,255,.1) 50%), linear-gradient(0deg, transparent 0%, rgba(1,1,1,.2) 50%);
  background-size: 4px,auto, auto,auto;
  background-color: #aa0101;
  box-shadow: 0 3px 3px 0 rgba(1,1,1,.5), 0 1px 0 0 rgba(1,1,1,.5), inset 0 -1px 8px 0 rgba(255,255,255,.3), inset 0 -1px 0 0 rgba(255,255,255,.2);
}
.cnrflash-inner:before, .cnrflash-inner:after
{
  /*Use the border triangle trick to make  				it look like the ribbon wraps round it's 				container*/
  content: " ";
  display: block;
  position: absolute;
  bottom: -16px;
  width: 0;
  height: 0;
  border: 8px solid #800000;
}
.cnrflash-inner:before
{
  left: 1px;
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.cnrflash-inner:after
{
  right: 0;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.cnrflash-label
{
  /*Make the label look nice*/
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  padding-bottom: 5px;
  color: #fff;
  text-shadow: 0 1px 1px rgba(1,1,1,.8);
  font-size: 0.95em;
  font-weight: bold;
  text-align: center;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  font-weight: bold !important;
}

p.metric-subnav {
  padding-top: 10px;
  padding-left: 50px;
  font-size: 18px;

}
